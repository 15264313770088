import { RouteConfig } from "vue-router";

const NomenclatorRoutes: Array<RouteConfig> = [
  {
    path: "",
    redirect: {
      name: "Subtypes nomenclator",
    },
  },
  {
    path: "procedures-subtypes",
    name: "Subtypes nomenclator",
    component: () =>
      import("@/views/Settings/Nomenclators/SubtypesNomenclator.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "medical-locations",
    name: "Medical locations",
    component: () =>
      import("@/views/Settings/Nomenclators/MedicalLocations.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "ocupations",
    name: "Ocupations",
    component: () => import("@/views/Settings/Nomenclators/Ocupations.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "accessories",
    name: "Accessories",
    component: () => import("@/views/Settings/Nomenclators/Accessories.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "references",
    name: "References",
    component: () => import("@/views/Settings/Nomenclators/References.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  /*  {
    path: "motives",
    name: "Motives",
    component: () => import("@/views/Settings/Nomenclators/Motives.vue"),
    meta: {
      requiresLogged: true,
    },
  }, */
];

export default NomenclatorRoutes;
