import { Themes } from "@/models/Colors";
import { Link } from "@/models/Link";
import { Settings } from "@/models/Settings";
import { Contact } from "@/models/Sms";

// USER MUTATION
export function updateLocalStorage(
  state: any,
  { access, refresh }: { access: string; refresh: string }
): void {
  if (access != null || access != undefined) {
    state.accessToken = access;
    localStorage.setItem("access_token", access);
  }

  if (refresh != null || refresh != undefined) {
    state.refreshToken = refresh;
    localStorage.setItem("refresh_token", refresh);
  }
}

export function updateAccess(state: any, access: any): void {
  if (
    access != null ||
    access != undefined ||
    (access as string).trim() != ""
  ) {
    state.accessToken = access;
  }
}

export function destroyToken(state: any): any {
  state.accessToken = null;
  state.refreshToken = null;
  // state.profile = null;
  // state.student = null;
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("profile");
  // localStorage.removeItem("student");
}

export function updateProfile(state: any, profile: any): any {
  state.profile = profile;
  localStorage.setItem("profile", JSON.stringify(profile));
}
export function updateUserContacts(state: any, contacts: []) {
  state.userContacts = contacts;
}
export function updateloadingContact(state: any, value: boolean) {
  state.loadingContact = value;
}
export function updateAvatar(state: any, avatar: string): any {
  state.avatar = avatar;
}
export function updateStatus(state: any, status: string): any {
  state.status = status;
}
export function updateactivesms(state: any, activesms: boolean): any {
  state.activesms = activesms;
}
export function muttimeWorked(state: any, time: any): any {
  state.timeWorked = time;
}

export function updateSidebarSelected(state: any, item: string): any {
  localStorage.setItem("sidebar_selected", item);
  state.sidebarSelected = item;
}

export function mutDrawerExpand(state: any, drawerExpanded: boolean): void {
  state.drawerExpanded = drawerExpanded;
}
export function mutDataCRM(state: any, datacrm: any): void {
  state.dataCRM = datacrm;
}
export function mutDataCompany(state: any, com: any): void {
  state.company = com;
}
export function mutDataCRMTitle(state: any, title: string): void {
  state.dataCRM.title = title;
}
export function mutDataCRMMenu(state: any, menu: Link[]): void {
  state.dataCRM.menu = menu;
}
export function mutDataCRMNomPrice(state: any, value: boolean): void {
  state.dataCRM.nomenclatorsPrice = value;
}
export function mutDataCRMBGColor(state: any, bgColor: string): void {
  state.dataCRM.bgColor = bgColor;
}
export function mutDataCRMColors(state: any, themes: Themes): void {
  state.dataCRM.themes = themes;
}
export function mutDataCRMNomenclators(state: any, noms: any): void {
  state.dataCRM.nomenclators = noms;
}
export function mutMethodCall(state: any, value: any): void {
  state.dataCRM.methodCall = value;
}
export function mutRequireNotes(state: any, value: boolean): void {
  state.dataCRM.requireNotes = value;
}
export function mutSellerN(state: any, value: string): void {
  state.dataCRM.sellerN = value;
}
export function mutPatientN(state: any, value: string): void {
  state.dataCRM.patientN = value;
}
export function mutCoordinatorN(state: any, value: string): void {
  state.dataCRM.coordinatorN = value;
}
export function mutviewpayments(state: any, value: boolean) {
  state.dataCRM.viewPayments = value;
}
export function mutviewPPS(state: any, value: boolean) {
  state.dataCRM.viewPPS = value;
}
export function mutviewLabs(state: any, value: boolean) {
  state.dataCRM.viewLabs = value;
}
export function mutviewMHistory(state: any, value: boolean) {
  state.dataCRM.viewMHistory = value;
}
export function mutTypesAppoint(state: any, value: any): void {
  state.dataCRM.appointmetsTypes = value;
}
export function mutUseDocuSign(state: any, value: boolean): void {
  state.useDocunsing = value;
}
export function mutBaseURL(state: any, baseurl: string): void {
  state.VUE_APP_BASE_URL = baseurl;
}
export function mutSettings(state: any, settings: Settings[]): void {
  state.settings = settings;
}
export function mutConfigID(state: any, uuid: string): void {
  state.configId = uuid;
}
export function mutReqRange(state: any, reqrange: any): void {
  state.reqrange = reqrange;
}
export function mutTotalItems(state: any, val: number): void {
  state.totalitems = val;
}

interface Notification {
  show: boolean;
  title?: string;
  body: string;
  type?: string;
}

export function mutNotification(state: any, notification: Notification): void {
  state.notification.type = notification.type || false;
  state.notification.title = notification.title || false;
  state.notification.body = notification.body || false;
  state.notification.show = notification.show;
}

export function mutShowNotification(state: any, value: boolean): void {
  state.notification.show = value;
}

export function mutcontactSelect(state: any, contactselect: Contact): void {
  state.contactSelect = contactselect;
}

/**
 * Set the Twilio Device status
 */
export function mutDeviceReady(state: any, status: boolean): void {
  state.twilioDevice.deviceReady = status;
}

export function mutShowDrawer(state: any, status: boolean): void {
  state.showDrawer = status;
}
export function setAccessToken(state: any, accessToken: string) {
  state.accessToken = accessToken;
}
export function setRefreshToken(state: any, refreshToken: string) {
  state.accessToken = refreshToken;
}
